import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const CookieBanner = () => {
  const [show, setShow] = useState(0);
  const [cookies, setCookie] = useCookies(["cookieConsent"]);

  useEffect(() => {
    if (!cookies.cookieConsent) {
      setShow(true);
    }
  }, [setShow, cookies]);

  const handleClick = () => {
    setShow(false);
    const expires = new Date();
    expires.setDate(expires.getDate() + 365);
    setCookie("cookieConsent", true, { expires: expires });
  };

  return (
    <>
      {show ? (
        <div className="cookie-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-10">
                <p className="text-white">
                  Използваме "бисквитки", за да персонализираме съдържанието и
                  рекламите, да предоставим функции на социалните медии и да
                  анализираме трафика на уебсайта. Ако продължите да използвате
                  уебсайта, Вие се съгласявате с нашата{" "}
                  <Link to="/cookie-policy" className="text-gold">
                    политика за бисквитки
                  </Link>
                  .
                </p>
              </div>
              <div className="col-lg-2 d-flex justify-content-center align-items-center">
                <span
                  className="cookie-accept btn btn-secondary btn-sm"
                  role={"button"}
                  onClick={handleClick}
                  onKeyUp={() => {}}
                  tabIndex={0}
                >
                  Приемам
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default CookieBanner;
